
import { computed, defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import LoanData from "@/data/loan";
import ApiService from "@/core/services/ApiService";
import { ElButton, ElForm, ElNotification } from "element-plus";
import moment from "moment";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import store from "@/store";

export default defineComponent({
  name: "LoanForm",
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Зээл бүртгэх");
    });
    const citizenCustFormRef = ref<InstanceType<typeof ElForm>>();
    const orgCustFormRef = ref<InstanceType<typeof ElForm>>();
    const loanFormRef = ref<InstanceType<typeof ElForm>>();
    const submitOrgRef = ref<InstanceType<typeof ElButton>>();
    const submitCustRef = ref<InstanceType<typeof ElButton>>();

    const formSubmitOrg = () => {
      orgCustFormRef.value?.validate(async valid => {
        loanFormRef.value?.validate(async validd => {
          if (valid && validd) submitOrgRef.value?.$el.click();
          else return false;
        });
      });
    };

    const formSubmitCust = () => {
      citizenCustFormRef.value?.validate(async valid => {
        loanFormRef.value?.validate(async validd => {
          if (valid && validd) submitCustRef.value?.$el.click();
          else return false;
        });
      });
    };

    const clearFieldOrg = () => {
      orgCustFormRef.value?.resetFields();
      loanFormRef.value?.resetFields();
    };

    const clearFieldCust = () => {
      citizenCustFormRef.value?.resetFields();
      loanFormRef.value?.resetFields();
    };

    const role = computed(() => {
      return store.getters.getRoleNames;
    });

    return {
      role,
      citizenCustFormRef,
      orgCustFormRef,
      loanFormRef,
      submitOrgRef,
      submitCustRef,
      formSubmitOrg,
      formSubmitCust,
      clearFieldOrg,
      clearFieldCust
    };
  },
  mounted() {
    if (
      this.role.includes("ROLE_FORM_MENU") ||
      this.role.includes("ROLE_ADMIN")
    )
      this.getData();
  },
  data() {
    const version = process.env.VUE_APP_LOAN_FORM_EL_ALERT_DESC;
    return {
      version,
      dataLoading: true,
      /************************************** Citizen Form data **/
      citizenCustForm: {
        registerno: "",
        passportno: "",
        givenname: "",
        surname: "",
        birthdate: "",
        zipcode: "",
        address: "",
        selectStatusCitizen: {
          data: LoanData[0]["selectStatusCitizen"],
          selectValCitizen: "01"
        },
        selectOccupationCitizen: {
          data: [],
          itemid: "",
          itemcode: "Occupation"
        },
        job: "",
        unemployedmembers: undefined,
        familymembers: undefined
      },
      /***************************************** Citizen Form rule **/
      citizenCustFormRule: {
        registerno: [
          {
            required: true,
            message: "Хоосон байна.",
            trigger: "blur"
          },
          {
            len: 10,
            message: "Буруу байна.",
            trigger: "blur"
          }
        ],
        givenname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        surname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        birthdate: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        address: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        zipcode: {
          len: 5,
          message: "5 тэмдэгт байна.",
          trigger: "blur"
        },
        selectOccupationCitizen: {
          itemid: {
            required: true,
            message: "Хоосон байна.",
            trigger: "change"
          }
        }
      },
      /***************************************** Org Form data **/
      orgCustForm: {
        registerno: "",
        registernoPre: "",
        presidentgivenname: "",
        presidentsurname: "",
        passportno: "",
        givenname: "",
        birthdate: "",
        zipcode: "",
        isforeign: "",
        stateregnum: "",
        socialPresident: "01",
        address: "",
        selectStatusOrg: {
          data: LoanData[0]["selectStatusOrg"],
          selectValOrg: ""
        },
        selectCompanyTypeCode: {
          data: [],
          itemid: "",
          itemcode: "CompanyType"
        },
        selectFitchRating: {
          data: [],
          itemid: "",
          itemcode: "FitchRating"
        },
        selectSandpRating: {
          data: [],
          itemid: "",
          itemcode: "Sandp_rating"
        },
        selectMoodysRating: {
          data: [],
          itemid: "",
          itemcode: "MoodysRating"
        },
        numofemployee: undefined,
        numofmainsector: undefined
      },
      /***************************************** Org Form rule **/
      orgCustFormRule: {
        selectStatusOrg: {
          selectValOrg: {
            required: true,
            message: "Хоосон байна.",
            trigger: "change"
          }
        },
        selectCompanyTypeCode: {
          itemid: {
            required: true,
            message: "Хоосон байна.",
            trigger: "change"
          }
        },
        isforeign: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        registerno: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        givenname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        type: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        birthdate: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        zipcode: {
          len: 5,
          message: "5 тэмдэгт байна.",
          trigger: "blur"
        },
        address: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        registernoPre: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        presidentsurname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        },
        presidentgivenname: {
          required: true,
          message: "Хоосон байна.",
          trigger: "blur"
        }
      },
      /*************************************** Loan Form data **/
      loanForm: {
        advamount: "",
        startdate: "",
        expdate: "",
        interestin: "",
        comissionin: "",
        loanfee: "",
        loandescription: "",
        savebuttonloading: false,
        selectLoanType: {
          data: [],
          itemid: "01",
          itemcode: "LoanType"
        },
        selectLoanClass: {
          data: [],
          itemid: "01",
          itemcode: "LoanClass"
        },
        selectCurrency: {
          data: [],
          currencycode: "MNT"
        },
        selectLoanPurpose1: {
          data: [],
          sectorcode: ""
        },
        selectLoanPurpose2: {
          data: [],
          subsectorcode: ""
        },
        selectLoanSource: {
          data: [],
          itemid: "",
          itemcode: "LoanSource"
        },
        selectloaninterestcharttype: {
          data: LoanData[0]["selectloancharttype"],
          selectloancharttypeval: "01"
        },
        selectloancharttype: {
          data: LoanData[0]["selectloancharttype"],
          selectloancharttypeval: "01"
        }
      },
      /***************************************** Loan Form rule **/
      loanFormRule: {
        advamount: [
          {
            required: true,
            message: "Хоосон байна.",
            trigger: "blur"
          }
        ],
        startdate: {
          required: true,
          message: "Хоосон байна.",
          trigger: "change"
        },
        selectLoanPurpose1: {
          sectorcode: {
            required: true,
            message: "Хоосон байна.",
            trigger: "change"
          }
        },
        selectLoanPurpose2: {
          subsectorcode: {
            required: true,
            message: "Хоосон байна.",
            trigger: "change"
          }
        },
        selectLoanSource: {
          itemid: {
            required: true,
            message: "Хоосон байна.",
            trigger: "change"
          }
        },
        expdate: [
          {
            required: true,
            // validator: checkCurrency,
            message: "Хоосон байна.",
            // format: "yyyy-MM-dd",
            trigger: "blur"
          }
        ]
      },
      selectStatus: {
        data: [
          {
            selectStatusVal: "01",
            selectStatusLabel: "Иргэн"
          },
          {
            selectStatusVal: "03",
            selectStatusLabel: "Хуулийн этгээд"
          }
        ],
        selectStatusVal: "01"
      }
    };
  },
  methods: {
    async getData() {
      this.dataLoading = true;
      await ApiService.get("loanform")
        .then(res => {
          /** Citizen Customer Form data **/
          this.citizenCustForm.selectOccupationCitizen.data =
            res.data.occupations;

          /** Organization Customer Form data **/
          this.orgCustForm.selectCompanyTypeCode.data =
            res.data.companytypecode;
          this.orgCustForm.selectFitchRating.data = res.data.fitchrating;
          this.orgCustForm.selectSandpRating.data = res.data.sandprating;
          this.orgCustForm.selectMoodysRating.data = res.data.moodysrating;

          /** Loan Form data **/
          this.loanForm.selectLoanSource.data = res.data.loansource;
          this.loanForm.selectLoanPurpose1.data = res.data.loanpurpose1;
          this.loanForm.selectLoanPurpose2.data = res.data.loanpurpose2;
          this.loanForm.selectCurrency.data = res.data.currencies;
          this.loanForm.selectLoanClass.data = res.data.loanclasses;
          this.loanForm.selectLoanType.data = res.data.loantypecode;
        })
        .catch(() => {
          ElNotification({
            title: "Алдаа",
            message: "Өгөгдөл уншихад алдаа гарлаа.",
            type: "error",
            iconClass: "el-text-error"
          });
        })
        .finally(() => {
          this.dataLoading = false;
        });
    },

    async submitCitizenForm() {
      this.loanForm.savebuttonloading = true;
      const params = {
        selectCustomerType: this.selectStatus.selectStatusVal,

        registerno: this.citizenCustForm.registerno,
        social: this.citizenCustForm.selectStatusCitizen.selectValCitizen,
        passportno: this.citizenCustForm.passportno,
        surname: this.citizenCustForm.surname,
        givenname: this.citizenCustForm.givenname,
        birthdate: this.citizenCustForm.birthdate,
        zipcode: this.citizenCustForm.zipcode,
        address: this.citizenCustForm.address,
        occupation: this.citizenCustForm.selectOccupationCitizen.itemid,
        job: this.citizenCustForm.job,
        unemployedmembers: this.citizenCustForm.unemployedmembers,
        familymembers: this.citizenCustForm.familymembers,

        loantype: this.loanForm.selectLoanType.itemid,
        loanclass: this.loanForm.selectLoanClass.itemid,
        advamount: this.loanForm.advamount,
        currency: this.loanForm.selectCurrency.currencycode,
        startdate: moment(this.loanForm.startdate).format("YYYYMMDDHHmmss"),
        starteddate: this.loanForm.startdate,
        enddate: this.loanForm.expdate,
        loanpurpose1: this.loanForm.selectLoanPurpose1.sectorcode,
        loanpurpose2: this.loanForm.selectLoanPurpose2.subsectorcode,
        loansource: this.loanForm.selectLoanSource.itemid,
        interestin: this.loanForm.interestin,
        comissionin: this.loanForm.comissionin,
        loanfee: this.loanForm.loanfee,
        loancharttype: this.loanForm.selectloancharttype.selectloancharttypeval,
        loaninterestcharttype: this.loanForm.selectloaninterestcharttype
          .selectloancharttypeval,
        loandescription: this.loanForm.loandescription
      };
      await ApiService.post("loanform", {
        params: params
      })
        .then(resp => {
          this.loanForm.savebuttonloading = false;
          this.citizenCustForm.registerno = "";
          this.citizenCustForm.selectStatusCitizen.selectValCitizen = "";
          this.citizenCustForm.passportno = "";
          this.citizenCustForm.surname = "";
          this.citizenCustForm.givenname = "";
          this.citizenCustForm.birthdate = "";
          this.citizenCustForm.zipcode = "";
          this.citizenCustForm.address = "";
          this.citizenCustForm.selectOccupationCitizen.itemid = "";
          this.citizenCustForm.job = "";
          this.citizenCustForm.unemployedmembers = undefined;
          this.citizenCustForm.familymembers = undefined;

          this.loanForm.selectLoanType.itemid = "";
          this.loanForm.selectLoanClass.itemid = "";
          this.loanForm.advamount = "";
          this.loanForm.selectCurrency.currencycode = "";
          this.loanForm.startdate = "";
          this.loanForm.expdate = "";
          this.loanForm.selectLoanPurpose1.sectorcode = "";
          this.loanForm.selectLoanPurpose2.subsectorcode = "";
          this.loanForm.selectLoanSource.itemid = "";
          this.loanForm.interestin = "";
          this.loanForm.comissionin = "";
          this.loanForm.loanfee = "";
          this.loanForm.selectloancharttype.selectloancharttypeval = "";
          this.loanForm.selectloaninterestcharttype.selectloancharttypeval = "";
          this.loanForm.loandescription = "";
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.loanForm.savebuttonloading = false;
          Swal.fire({
            text: "Зээл бүртгэхэд алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    },

    async submitOrgForm() {
      this.loanForm.savebuttonloading = true;
      const params = {
        selectCustomerType: this.selectStatus.selectStatusVal,

        registerno: this.orgCustForm.registerno,
        social: this.orgCustForm.selectStatusOrg.selectValOrg,
        givenname: this.orgCustForm.givenname,
        presidentgivenname: this.orgCustForm.presidentgivenname,
        presidentsurname: this.orgCustForm.presidentsurname,
        socialPresident: this.orgCustForm.socialPresident,
        presidentregid: this.orgCustForm.registernoPre,
        birthdate: this.orgCustForm.birthdate,
        zipcode: this.orgCustForm.zipcode,
        address: this.orgCustForm.address,
        isforeign: this.orgCustForm.isforeign,
        stateregnum: this.orgCustForm.stateregnum,
        companytypecode: this.orgCustForm.selectCompanyTypeCode.itemid,
        numofmainsector: this.orgCustForm.numofmainsector,
        numofemployee: this.orgCustForm.numofemployee,
        fitchrating: this.orgCustForm.selectFitchRating.itemid,
        sandpRating: this.orgCustForm.selectSandpRating.itemid,
        moodysrating: this.orgCustForm.selectMoodysRating.itemid,

        loantype: this.loanForm.selectLoanType.itemid,
        loanclass: this.loanForm.selectLoanClass.itemid,
        advamount: this.loanForm.advamount,
        currency: this.loanForm.selectCurrency.currencycode,
        startdate: moment(this.loanForm.startdate).format("YYYYMMDDHHmmss"),
        starteddate: this.loanForm.startdate,
        enddate: this.loanForm.expdate,
        loanpurpose1: this.loanForm.selectLoanPurpose1.sectorcode,
        loanpurpose2: this.loanForm.selectLoanPurpose2.subsectorcode,
        loansource: this.loanForm.selectLoanSource.itemid,
        interestin: this.loanForm.interestin,
        comissionin: this.loanForm.comissionin,
        loanfee: this.loanForm.loanfee,
        loancharttype: this.loanForm.selectloancharttype.selectloancharttypeval,
        loaninterestcharttype: this.loanForm.selectloaninterestcharttype
          .selectloancharttypeval,
        loandescription: this.loanForm.loandescription
      };
      await ApiService.post("loanform", {
        params: params
      })
        .then(resp => {
          this.loanForm.savebuttonloading = false;

          this.orgCustForm.registerno = "";
          this.orgCustForm.selectStatusOrg.selectValOrg = "";
          this.orgCustForm.givenname = "";
          this.orgCustForm.presidentgivenname = "";
          this.orgCustForm.presidentsurname = "";
          this.orgCustForm.socialPresident = "";
          this.orgCustForm.registernoPre = "";
          this.orgCustForm.birthdate = "";
          this.orgCustForm.zipcode = "";
          this.orgCustForm.address = "";
          this.orgCustForm.isforeign = "";
          this.orgCustForm.stateregnum = "";
          this.orgCustForm.selectCompanyTypeCode.itemid = "";
          this.orgCustForm.numofmainsector = undefined;
          this.orgCustForm.numofemployee = undefined;
          this.orgCustForm.selectFitchRating.itemid = "";
          this.orgCustForm.selectSandpRating.itemid = "";
          this.orgCustForm.selectMoodysRating.itemid = "";

          this.loanForm.selectLoanType.itemid = "";
          this.loanForm.selectLoanClass.itemid = "";
          this.loanForm.advamount = "";
          this.loanForm.selectCurrency.currencycode = "";
          this.loanForm.startdate = "";
          this.loanForm.expdate = "";
          this.loanForm.selectLoanPurpose1.sectorcode = "";
          this.loanForm.selectLoanPurpose2.subsectorcode = "";
          this.loanForm.selectLoanSource.itemid = "";
          this.loanForm.interestin = "";
          this.loanForm.comissionin = "";
          this.loanForm.loanfee = "";
          this.loanForm.selectloancharttype.selectloancharttypeval = "";
          this.loanForm.selectloaninterestcharttype.selectloancharttypeval = "";
          this.loanForm.loandescription = "";
          if (resp.data.code === 1) {
            Swal.fire({
              text: resp.data.response,
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn fw-bold btn-light-success"
              }
            });
          } else {
            Swal.fire({
              text: resp.data.error,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Буцах",
              customClass: {
                confirmButton: "btn fw-bold btn-light-error"
              }
            });
          }
        })
        .catch(() => {
          this.loanForm.savebuttonloading = false;
          Swal.fire({
            text: "Зээл бүртгэхэд алдаа гарлаа.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Буцах",
            customClass: {
              confirmButton: "btn fw-bold btn-light-error"
            }
          });
        });
    }
  }
});
